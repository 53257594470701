var render = function render(){var _vm=this,_c=_vm._self._c;return _c('form',{ref:"profile_form",on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.NAME')} (*)`,"required":true},on:{"change":() => {
            _vm.onFormChanged();
          }},model:{value:(_vm.cashRegister.name),callback:function ($$v) {_vm.$set(_vm.cashRegister, "name", $$v)},expression:"cashRegister.name"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.name}})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.CODE')} (*)`,"required":true},on:{"change":() => {
            _vm.onFormChanged();
          }},model:{value:(_vm.cashRegister.code),callback:function ($$v) {_vm.$set(_vm.cashRegister, "code", $$v)},expression:"cashRegister.code"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.code}})],1)]),(
      _vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_ORGANIZATIONS) && !_vm.hideOrganization
    )?_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.ORGANIZATION')} (*)`,"placeholder":_vm.$t('COMMON.ORGANIZATION')}},[_c('organization-selector',{attrs:{"organization":_vm.cashRegister.organization.id,"filterable":true,"showAll":false,"disabled":!!_vm.cashRegister.id},on:{"organizationChanged":(organizationId) => {
          _vm.cashRegister.organization.id = organizationId;
          _vm.onFormChanged();
        }}})],1):_vm._e(),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.organization}}),(
      _vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_ESTABLISHMENTS) && !_vm.hideOrganization
    )?_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.SERVICECENTER')}`,"placeholder":_vm.$t('COMMON.SERVICECENTER')}},[_c('service-center-selector',{attrs:{"filterOrganization":_vm.cashRegister.organization?.id,"serviceCenter":_vm.cashRegister.serviceCenter?.id,"filterable":true,"showAll":false},on:{"serviceCenterChanged":(serviceCenterId) => {
          _vm.cashRegister.serviceCenter.id = serviceCenterId;
          _vm.onFormChanged();
        }}})],1):_vm._e(),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.serviceCenter}}),(
      _vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_ESTABLISHMENTS) && !_vm.hideOrganization
    )?_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.ESTABLISHMENT')}`,"placeholder":_vm.$t('COMMON.ESTABLISHMENT')}},[_c('establishment-selector',{attrs:{"filterOrganization":_vm.cashRegister.organization?.id,"establishment":_vm.cashRegister.establishment?.id,"filterable":true,"filterServiceCenter":_vm.cashRegister.serviceCenter?.id,"showAll":false},on:{"establishmentChanged":(establishmentId, establishment) => {
          _vm.cashRegister.establishment.id = establishmentId;
          if (establishment.warehouse?.id) {
            _vm.cashRegister.warehouse.id = establishment.warehouse.id;
          }
          _vm.onFormChanged();
        }}})],1):_vm._e(),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.establishment}}),(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_CAFETERIAS) && !_vm.hideOrganization)?_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.CAFETERIA')}`,"placeholder":_vm.$t('COMMON.CAFETERIA')}},[_c('cafeteria-selector',{attrs:{"filterOrganization":_vm.cashRegister.organization?.id,"cafeteria":_vm.cashRegister.cafeteria?.id,"filterEstablishment":_vm.cashRegister.establishment?.id,"filterable":true,"showAll":false},on:{"cafeteriaChanged":(cafeteriaId, cafeteria) => {
          _vm.cashRegister.cafeteria.id = cafeteriaId;
          if (cafeteria.warehouse?.id) {
            _vm.cashRegister.warehouse.id = cafeteria.warehouse.id;
          }
          _vm.onFormChanged();
        }}})],1):_vm._e(),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.cafeteria}}),(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_WAREHOUSES) && !_vm.hideOrganization)?_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.WAREHOUSE')}`,"placeholder":_vm.$t('COMMON.WAREHOUSE')}},[_c('warehouse-selector',{attrs:{"filterOrganization":_vm.cashRegister.organization?.id,"warehouse":_vm.cashRegister.warehouse?.id,"filterable":true,"showAll":false},on:{"warehouseChanged":(warehouseId) => {
          _vm.cashRegister.warehouse.id = warehouseId;
          _vm.onFormChanged();
        }}})],1):_vm._e(),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.warehouse}}),_c('div',{staticClass:"my-4"},[_c('base-button',{staticClass:"btn btn-sm btn-primary",attrs:{"type":"button","native-type":"submit","disabled":_vm.loading}},[(_vm.loading)?_c('i',{staticClass:"fas fa-spinner fa-spin"}):_vm._e(),_vm._v(" "+_vm._s(_vm.cashRegister.id ? _vm.$t("CASH_REGISTERS.EDIT_CASH_REGISTER") : _vm.$t("CASH_REGISTERS.ADD_CASH_REGISTER"))+" ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }