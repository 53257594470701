<template>
  <form ref="profile_form" @submit.prevent="handleSubmit">
    <div class="row">
      <div class="col">
        <base-input
          :label="`${$t('COMMON.NAME')} (*)`"
          :required="true"
          v-model="cashRegister.name"
          @change="
            () => {
              onFormChanged();
            }
          "
        />
        <validation-error :errors="apiValidationErrors.name" />
      </div>
    </div>
    <div class="row">
      <div class="col">
        <base-input
          :label="`${$t('COMMON.CODE')} (*)`"
          :required="true"
          v-model="cashRegister.code"
          @change="
            () => {
              onFormChanged();
            }
          "
        />
        <validation-error :errors="apiValidationErrors.code" />
      </div>
    </div>
    <base-input
      :label="`${$t('COMMON.ORGANIZATION')} (*)`"
      :placeholder="$t('COMMON.ORGANIZATION')"
      v-if="
        $currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS) && !hideOrganization
      "
    >
      <organization-selector
        :organization="cashRegister.organization.id"
        :filterable="true"
        :showAll="false"
        @organizationChanged="
          (organizationId) => {
            cashRegister.organization.id = organizationId;
            onFormChanged();
          }
        "
        :disabled="!!cashRegister.id"
      />
    </base-input>
    <validation-error :errors="apiValidationErrors.organization" />

    <base-input
      :label="`${$t('COMMON.SERVICECENTER')}`"
      :placeholder="$t('COMMON.SERVICECENTER')"
      v-if="
        $currentUserCan($permissions.PERM_VIEW_ANY_ESTABLISHMENTS) && !hideOrganization
      "
    >
      <service-center-selector
        :filterOrganization="cashRegister.organization?.id"
        :serviceCenter="cashRegister.serviceCenter?.id"
        :filterable="true"
        :showAll="false"
        @serviceCenterChanged="
          (serviceCenterId) => {
            cashRegister.serviceCenter.id = serviceCenterId;
            onFormChanged();
          }
        "
      />
    </base-input>
    <validation-error :errors="apiValidationErrors.serviceCenter" />

    <base-input
      :label="`${$t('COMMON.ESTABLISHMENT')}`"
      :placeholder="$t('COMMON.ESTABLISHMENT')"
      v-if="
        $currentUserCan($permissions.PERM_VIEW_ANY_ESTABLISHMENTS) && !hideOrganization
      "
    >
      <establishment-selector
        :filterOrganization="cashRegister.organization?.id"
        :establishment="cashRegister.establishment?.id"
        :filterable="true"
        :filterServiceCenter="cashRegister.serviceCenter?.id"
        :showAll="false"
        @establishmentChanged="
          (establishmentId, establishment) => {
            cashRegister.establishment.id = establishmentId;
            if (establishment.warehouse?.id) {
              cashRegister.warehouse.id = establishment.warehouse.id;
            }
            onFormChanged();
          }
        "
      />
    </base-input>
    <validation-error :errors="apiValidationErrors.establishment" />

    <base-input
      :label="`${$t('COMMON.CAFETERIA')}`"
      :placeholder="$t('COMMON.CAFETERIA')"
      v-if="$currentUserCan($permissions.PERM_VIEW_ANY_CAFETERIAS) && !hideOrganization"
    >
      <cafeteria-selector
        :filterOrganization="cashRegister.organization?.id"
        :cafeteria="cashRegister.cafeteria?.id"
        :filterEstablishment="cashRegister.establishment?.id"
        :filterable="true"
        :showAll="false"
        @cafeteriaChanged="
          (cafeteriaId, cafeteria) => {
            cashRegister.cafeteria.id = cafeteriaId;
            if (cafeteria.warehouse?.id) {
              cashRegister.warehouse.id = cafeteria.warehouse.id;
            }
            onFormChanged();
          }
        "
      />
    </base-input>
    <validation-error :errors="apiValidationErrors.cafeteria" />
    <base-input
      :label="`${$t('COMMON.WAREHOUSE')}`"
      :placeholder="$t('COMMON.WAREHOUSE')"
      v-if="$currentUserCan($permissions.PERM_VIEW_ANY_WAREHOUSES) && !hideOrganization"
    >
      <warehouse-selector
        :filterOrganization="cashRegister.organization?.id"
        :warehouse="cashRegister.warehouse?.id"
        :filterable="true"
        :showAll="false"
        @warehouseChanged="
          (warehouseId) => {
            cashRegister.warehouse.id = warehouseId;
            onFormChanged();
          }
        "
      />
    </base-input>
    <validation-error :errors="apiValidationErrors.warehouse" />

    <div class="my-4">
      <base-button
        type="button"
        class="btn btn-sm btn-primary"
        native-type="submit"
        :disabled="loading"
      >
        <i class="fas fa-spinner fa-spin" v-if="loading" />
        {{
          cashRegister.id
            ? $t("CASH_REGISTERS.EDIT_CASH_REGISTER")
            : $t("CASH_REGISTERS.ADD_CASH_REGISTER")
        }}
      </base-button>
    </div>
  </form>
</template>
<script>
import { cloneDeep } from "lodash";
import formMixin from "@/mixins/form-mixin";
import ValidationError from "@/components/ValidationError.vue";
import BaseInput from "@/components/Inputs/BaseInput.vue";
import OrganizationSelector from "@/components/OrganizationSelector.vue";
import EstablishmentSelector from "@/components/EstablishmentSelector.vue";
import CafeteriaSelector from "@/components/CafeteriaSelector.vue";
import ServiceCenterSelector from "@/components/ServiceCenterSelector.vue";
import WarehouseSelector from "@/components/WarehouseSelector.vue";

export default {
  layout: "DashboardLayout",

  components: {
    BaseInput,
    ValidationError,
    OrganizationSelector,
    EstablishmentSelector,
    CafeteriaSelector,
    ServiceCenterSelector,
    WarehouseSelector,
  },

  mixins: [formMixin],

  props: ["cashRegisterData", "formErrors", "loading", "hideOrganization", "hideSubmit"],

  data() {
    let cashRegisterData = { ...this.cashRegisterData };
    cashRegisterData = this.$fillUserOrganizationData(cashRegisterData);
    return {
      cashRegister: cashRegisterData,
    };
  },

  created() {},

  methods: {
    async handleSubmit() {
      let cashRegisterData = cloneDeep(this.cashRegister);
      cashRegisterData = this.$fillUserOrganizationData(cashRegisterData);
      this.$emit("cashRegisterSubmitted", cashRegisterData);
    },

    onFormChanged() {
      this.$emit("formChanged");
    },
  },

  watch: {
    formErrors(errors) {
      if (errors) {
        this.setApiValidation(errors);
      }
    },
    cashRegisterData(cashRegisterData) {
      if (cashRegisterData) {
        this.cashRegister = {
          ...this.cashRegister,
          ...cloneDeep(cashRegisterData),
        };
      }
    },
  },
};
</script>
